import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponse } from '@models/BaseResponse';
import { environment } from 'src/environments/environment';
import { Participant } from '@models/Participant';
import { TripUserStatus } from '@enums/TripUserStatus';
import { TripUserRole } from '@enums/TripUserRole';
import { Trip } from '@models/Trip';
import { Observable, map } from 'rxjs';
import { TripDraft } from '@models/TripDraft';
import { DateTime } from 'luxon';

export type TripInvite = {
  invited: Participant[];
  notValid: string[];
}

@Injectable({
  providedIn: 'root'
})
export class TripService {

  public constructor(
    private readonly http: HttpClient
  ) { }

  public getTripPreviewList(type: 'incoming' | 'completed' = 'incoming'): Observable<BaseResponse<Trip[]>> {
    return this.http.get<BaseResponse<Trip[]>>(`${environment.backendUrl}/api/trips/preview-list`, {
      params: { type }
    });
  }

  public createOrUpdate(fromDraft: TripDraft) {
    return this.http.post<BaseResponse<{ id: string }>>(`${environment.backendUrl}/api/trips`, { ...fromDraft });
  }

  public getById(id: string) {
    return this.http.get<BaseResponse<Trip>>(`${environment.backendUrl}/api/trips/${id}`).pipe(
      map(resp => {
        if (resp.data.days.length > 0) {
          for (const day of Object.values(resp.data.days)) {
            if (day.locations.length > 0) {
              for (const location of Object.values(day.locations)) {
                // Drops seconds
                location.pivot.time = DateTime.fromFormat(location.pivot.time, 'HH:mm:ss').toFormat('HH:mm');
              }
            }
          }
        }

        return resp;
      })
    );
  }

  public inviteParticipant(id: string, emails: string[]) {
    return this.http.post<BaseResponse<TripInvite>>(`${environment.backendUrl}/api/trips/${id}/participants`, { emails });
  }

  public updateParticipantRole(id: string, userID: string, role: TripUserRole) {
    return this.http.patch<BaseResponse<TripInvite>>(`${environment.backendUrl}/api/trips/${id}/participants/role`, { userID, role });
  }

  public updateParticipantStatus(id: string, status: TripUserStatus) {
    return this.http.patch<BaseResponse<boolean>>(`${environment.backendUrl}/api/trips/${id}/participants/status`, { status });
  }

  public removeParticipant(id: string, userID: string) {
    return this.http.delete<BaseResponse<boolean>>(`${environment.backendUrl}/api/trips/${id}/participants/${userID}`);
  }
}
