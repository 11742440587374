import { Component, Input } from '@angular/core';

@Component({
  selector: 'MainLayout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {

  @Input() public hasHeader = true;

}
