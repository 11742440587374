<div>
  <button
    *ngIf="mode === 'rw'"
    mat-raised-button
    (click)="openDialog(addDay)"
    aria-label="Aggiungi una giornata"
  >
    <mat-icon aria-hidden="true" fontIcon="add"></mat-icon>
    Giornata
  </button>

  <div *ngIf="days.length > 0">
    <span class="separator"></span>

    <button
      *ngFor="let day of days"
      class="day"
      [ngClass]="{
        selected: selectedDay?.day === day.day
      }"
    >
      <a
        [routerLink]="['.']"
        [queryParams]="{ day: day.day }"
        queryParamsHandling="merge"
      >
        <p class="day-name">
          {{ "days." + (day.day | date : "EEEE")! | translate }}
        </p>
        <h3
          class="day-number"
          [attr.aria-label]="day.day | date : 'dd/MM/yyyy'"
        >
          {{ day.day | date : "dd" }}
        </h3>
        <p class="month-name">
          {{ "months." + (day.day | date : "MMMM")! | translate }}
        </p>
      </a>
    </button>

    <span class="separator"></span>
  </div>
</div>

<ng-template #addDay>
  <div mat-dialog-content>
    <mat-form-field>
      <mat-label>Scegli una data</mat-label>
      <input
        matInput
        type="date"
        #addDayInput
        cdkFocusInitial
        [min]="addDayBoundaries![0]"
        [max]="addDayBoundaries![1]"
      />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Annulla</button>
    <button
      mat-button
      type="submit"
      color="primary"
      [mat-dialog-close]="addDayInput.value"
      [disabled]="addDayInput.value.length === 0"
    >
      Aggiungi
    </button>
  </div>
</ng-template>
