import { TripUserRole } from '@enums/TripUserRole';
import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Participant } from '@models/Participant';
import { TripService } from '@services/trip.service';
import { COMMA, SPACE } from '@angular/cdk/keycodes';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TripEnhanced } from '@models/Trip';
import { ErrorDialogComponent } from '@dialogs/error-dialog/error-dialog.component';
import { AuthService } from '@services/auth.service';
import { TripUserService } from '@services/trip-user.service';

@Component({
  selector: 'trip-participants-widget',
  templateUrl: './participants.component.html',
  styleUrls: ['./participants.component.scss']
})
export class ParticipantsWidgetComponent {

  public readonly separatorKeysCodes = [COMMA, SPACE] as const;

  public readonly TripUserRole = TripUserRole;

  public readonly newParticipantsForm = new FormGroup({
    emails: new FormControl<string[]>([], [Validators.required, Validators.minLength(1)]),
  });

  @Input() public trip!: TripEnhanced;
  @Input() public participants!: Participant[];
  @Input() public currentUserAsParticipant!: Participant;

  public newParticipants: string[] = [];

  public clickedParticipant?: Participant;

  public constructor(
    private readonly tripService: TripService,
    private readonly authService: AuthService,
    public readonly tripUserService: TripUserService,

    private readonly dialog: MatDialog,
    public readonly dialogRef: MatDialogRef<ParticipantsWidgetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      trip: TripEnhanced;
      participants: Participant[];
      currentUserAsParticipant: Participant;
    },
  ) {
    this.trip = data.trip;
    this.participants = data.participants;
    this.currentUserAsParticipant = data.currentUserAsParticipant;
    this.sortParticipants();
  }

  private sortParticipants(): void {
    const authUserID = this.authService.user.value!.id;
    const excludingCurrentUser = this.participants.filter(p => p.user.id !== authUserID);

    const organizers = excludingCurrentUser.filter(p => p.role === TripUserRole.ORGANIZER);
    const assistants = excludingCurrentUser.filter(p => p.role === TripUserRole.ASSISTANT);
    const guests = excludingCurrentUser.filter(p => p.role === TripUserRole.GUEST);

    this.participants = [
      this.currentUserAsParticipant,
      ...organizers,
      ...assistants,
      ...guests,
    ];
  }

  public participantRoleIcon(role: string) {
    switch (role) {
      case TripUserRole.ORGANIZER:
        return 'verified_user';
      case TripUserRole.ASSISTANT:
        return 'manage_accounts';
      case TripUserRole.GUEST:
        return 'person';
      default:
        return '';
    }
  }


  public chipAdd(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.newParticipants.push(value);
      this.newParticipantsForm.patchValue({ emails: this.newParticipants });
    }

    // Clear the input value
    event.chipInput.clear();
  }

  public chipRemove(participant: string): void {
    const index = this.newParticipants.indexOf(participant);

    if (index >= 0) {
      this.newParticipants.splice(index, 1);
      this.newParticipantsForm.patchValue({ emails: this.newParticipants });
    }
  }

  public chipEdit(participant: string, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.chipRemove(participant);
      return;
    }

    // Edit existing fruit
    const index = this.newParticipants.indexOf(participant);
    if (index >= 0) {
      this.newParticipants[index] = value;
      this.newParticipantsForm.patchValue({ emails: this.newParticipants });
    }
  }

  public invite(): void {
    if (this.newParticipantsForm.valid) {
      this.tripService.inviteParticipant(this.trip.id, this.newParticipantsForm.value.emails!).subscribe({
        next: response => {
          this.participants = response.data.invited;
          this.sortParticipants();

          this.newParticipantsForm.patchValue({ emails: response.data.notValid });
          this.newParticipants = response.data.notValid;

          if (this.newParticipants.length > 0) {
            this.dialog.open(ErrorDialogComponent, {
              data: {
                text: '<p>Alcuni invitati non sono stati riconosciuti e i loro indirizzi e-mail sono stati preservati.</p><p>Potrebbero non essere registrati all\'applicazione.</p>',
              }
            });
          }
        }
      });
    }
  }

  public updateRole(participant: Participant, role: string): void {
    this.tripService.updateParticipantRole(this.trip.id, participant.user.id!, role as TripUserRole).subscribe({
      next: response => {
        if (response.data) {
          participant.role = role as TripUserRole;

        } else {
          this.dialog.open(ErrorDialogComponent, {
            data: {
              text: '<p>Dev\'esserci almeno un organizzatore del viaggio</p>',
            }
          });
        }
      }
    });
  }

  public remove(userID: string): void {
    this.tripService.removeParticipant(this.trip.id, userID).subscribe({
      next: response => {
        if (response.data) {
          this.participants = this.participants.filter(p => p.user.id !== userID);
        } else {
          this.dialog.open(ErrorDialogComponent, {
            data: {
              text: '<p>Non puoi rimuovere l\'unico organizzatore del viaggio</p>',
            }
          });
        }
      }
    });
  }

}
