import { Injectable } from '@angular/core';
import { TripUserRole } from '@enums/TripUserRole';

@Injectable({
  providedIn: 'root'
})
export class TripUserService {

  public hasAnyOfRoles(userRole: TripUserRole, roles: TripUserRole[]): boolean {
    return roles.includes(userRole);
  }
}
