import { CONSTANTS } from './../../../constants';
import { AuthService } from '@services/auth.service';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LogoutDialogComponent } from '@dialogs/logout-dialog/logout-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  public readonly CONSTANTS = CONSTANTS;

  public constructor(
    private readonly dialog: MatDialog,
    public readonly authService: AuthService,
  ) { }

  public logout(): void {
    this.dialog.open(LogoutDialogComponent, {
      maxWidth: '450px',
    });
  }

}
