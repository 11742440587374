import { TimePipe } from '@pipes/time.pipe';
import { Location } from '@models/Location';
import { Component, Input, OnChanges } from '@angular/core';
import { latLng, MapOptions, tileLayer, marker, icon, latLngBounds, LatLngBounds, Layer } from 'leaflet';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EditorService } from '@services/editor.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'trip-day-locations-widget',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsWidgetComponent implements OnChanges {

  @Input() public locations!: Location[];

  @Input() public mode: 'r' | 'rw' = 'r';

  public showList: boolean = false;

  public options: Partial<MapOptions> = {};

  public fitBounds?: LatLngBounds;

  public layers: Layer[] = [];

  public formGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    time: new FormControl('', [Validators.required]),
    link: new FormControl(''),
    latitude: new FormControl<number | undefined>(undefined, [Validators.required]),
    longitude: new FormControl<number | undefined>(undefined, [Validators.required]),
  });

  public constructor(
    private readonly timePipe: TimePipe,
    private readonly dialog: MatDialog,
    private readonly editorService: EditorService,
  ) { }

  public ngOnChanges(): void {
    this.options = {
      layers: [
        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18 }),
      ],
      scrollWheelZoom: false,
    }

    this.layers = this.locations.map(l => {
      return marker(
        latLng(l.latitude, l.longitude),
        {
          title: l.name,
          icon: icon({
            iconSize: [30, 45],
            iconAnchor: [15, 45],
            popupAnchor: [0, -50],
            iconUrl: '/assets/leaflet/marker-icon.png'
          })
        }
      ).bindPopup(this.buildPopup(l), { maxWidth: 600, maxHeight: 200 });
    });

    this.fitBounds = latLngBounds(this.locations.map(l => [l.latitude, l.longitude]));
  }

  private buildPopup(l: Location): string {
    const link = l.link ? `<a href="${l.link}" target="_blank">${l.name}</a>` : `<span>${l.name}</span>`;

    return `
    <div>
      <p>Orario: ${this.timePipe.transform(l.pivot.time)}</p>
      ${link}
    </div>
    `;
  }

  public openDialog(dialog: any, edit?: Location): void {
    if (edit) {
      this.formGroup.patchValue({
        name: edit.name,
        time: edit.pivot.time,
        link: edit.link,
        latitude: edit.latitude,
        longitude: edit.longitude,
      });
    }

    this.dialog.open(dialog).afterClosed().subscribe((values: any) => {
      if (Object.keys(values).length > 0 && this.formGroup.valid) {
        const newData: Location = {
          id: uuidv4(),
          name: values.name,
          link: values.link,
          latitude: Number(values.latitude),
          longitude: Number(values.longitude),
          pivot: {
            time: values.time,
          }
        };

        if (edit) {
          this.locations[this.locations.findIndex(l => l.id === edit.id)] = newData;
        } else {
          this.locations.push(newData);
        }

        this.formGroup.reset();

        this.editorService.setWidgetsData('locations', this.locations);
      }
    });
  }

  public remove({ id }: Location): void {
    const i = this.locations.findIndex(l => l.id === id);
    this.locations.splice(i, 1);
    this.editorService.setWidgetsData('locations', this.locations);
  }

}
