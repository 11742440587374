import { Component, Input } from '@angular/core';
import { Trip } from '@models/Trip';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { DateTime } from 'luxon';

@Component({
  selector: 'trip-preview',
  templateUrl: './trip-preview.component.html',
  styleUrls: ['./trip-preview.component.scss']
})
export class TripPreviewComponent {
  @Input() public trip!: Trip;
  @Input() public loading: boolean = false;

  constructor(
    private readonly datePipe: DatePipe,
    private readonly titleCasePipe: TitleCasePipe
  ) {

  }

  public get timeString(): string {
    if (
      Math.abs(
        Math.trunc(
          DateTime.fromISO(this.trip.start_date)
            .diff(DateTime.fromISO(this.trip.end_date), 'days')
            .days)
      ) === 0
    ) {
      const dateString = `${this.datePipe.transform(this.trip.start_date, 'EEE', undefined, 'it-IT')} ${this.datePipe.transform(this.trip.start_date, 'dd/MM/yyyy')}`;
      const timeString = `${this.datePipe.transform(this.trip.start_date, 'HH:mm')} - ${this.datePipe.transform(this.trip.end_date, 'HH:mm')}`;

      return `${this.titleCasePipe.transform(dateString)} ${timeString}`;
    } else {
      const startDateString = `${this.datePipe.transform(this.trip.start_date, 'dd/MM/yyyy')}`;
      const endDateString = `${this.datePipe.transform(this.trip.end_date, 'dd/MM/yyyy')}`;

      return `${startDateString} - ${endDateString}`;
    }
  }

  public get participantsCount(): number {
    return this.trip.participants.length;
  }
  public get participantsAcceptedCount(): number {
    return this.trip.participants.filter(p => p.status === 'accepted').length;
  }
}
