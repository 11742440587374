import { BaseResponse } from '@models/BaseResponse';
import { environment } from '../../environments/environment';
import { User } from '@models/User';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public authenticationInProgress = new BehaviorSubject<boolean>(false);
  public user = new BehaviorSubject<User | undefined>(undefined);

  public get isAuthenticated(): boolean {
    return this.cookieService.check('token') && !!this.user?.value;
  }

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly cookieService: CookieService,
  ) {
    const token: string | null = this.cookieService.get('token');

    if (token) {
      this.loginUserWithToken(token);
    } else {
      console.debug('User is not authenticated because a token is missing');
    }
  }

  public getUserFromApi(skipErrorHandling: boolean = false) {
    let headers = new HttpHeaders();
    headers = headers.append('skip-error-handling', `${skipErrorHandling}`);

    return this.http.get<BaseResponse<User>>(`${environment.backendUrl}/api/auth/user`, { headers });
  }

  public loginUserWithToken(token: string) {
    this.authenticationInProgress.next(true);

    this.cookieService.set('token', token, { path: '/', expires: 30 });

    this.getUserFromApi().subscribe({
      next: response => {
        this.user.next(response.data);
      },
      error: (error: HttpErrorResponse) => {
        if (error.status !== 401) {
          console.error(error);
        }

        this.logout();
      }
    }).add(() => this.authenticationInProgress.next(false));
  }

  public logout() {
    let headers = new HttpHeaders();
    headers = headers.append('skip-error-handling', "true");

    this.http.get<never>(`${environment.backendUrl}/api/auth/logout`, { headers }).subscribe({
      error: (error: HttpErrorResponse) => {
        console.error('Logout request failed', error);
      }
    }).add(() => {
      this.cookieService.delete('token', '/');
      this.user.next(undefined);
      this.router.navigate(['/']);
    });
  }
}
