export const environment = {
  production: true,
  backendUrl: 'https://api-travel.francescosorge.com',
  sentry: {
    dsn: "https://c3d53faa6bbe46ae822172bc0151d046@o4504633973276672.ingest.sentry.io/4504962972057600",
    tracePropagationTargets: [
      "https://api-travel.francescosorge.com"
    ],
  }
};
