import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginPageComponent } from '@pages/login-page/login-page.component';
import { HomePageComponent } from '@pages/home-page/home-page.component';
import { TripDetailsPageComponent } from '@pages/trips/details/trip-details-page.component';
import { TripDayCostsPageComponent } from '@pages/trips/day/costs-page/costs-page.component';
import { TripsPageComponent } from '@pages/trips/trips.component';
import { TripsEditorPageComponent } from '@pages/trips/editor/editor-page.component';
import { AuthenticatedGuard } from '@guards/authenticated.guard';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    data: {
      title: '%DEFAULT%',
    }
  },
  {
    path: 'login',
    component: LoginPageComponent,
    data: {
      title: 'login'
    }
  },
  {
    path: 'trips',
    canActivate: [AuthenticatedGuard.canActivate],
    children: [
      {
        path: '',
        component: TripsPageComponent,
        data: {
          title: 'trips.list'
        }
      },
      {
        path: 'editor',
        component: TripsEditorPageComponent,
        data: {
          title: 'trips.create'
        }
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            component: TripDetailsPageComponent,
            pathMatch: 'full'
          },
          {
            path: ':day',
            children: [
              {
                path: 'costs',
                component: TripDayCostsPageComponent,
              }
            ]
          }
        ]
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
