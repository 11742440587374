import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import * as Sentry from "@sentry/angular-ivy";
import { environment } from './environments/environment';

if (environment.production) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    integrations: [
      new Sentry.BrowserTracing({
        // https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/#tracepropagationtargets
        tracePropagationTargets: environment.sentry.tracePropagationTargets,
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  });
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
