<MainLayout>
  <div *ngIf="trip && currentUserAsParticipant" id="trip">
    <div
      *ngIf="
        tripUserService.hasAnyOfRoles(currentUserAsParticipant.role, [
          TripUserRole.ORGANIZER,
          TripUserRole.ASSISTANT
        ])
      "
    >
      <a
        [routerLink]="['/', 'trips', 'editor']"
        [queryParams]="{ 'trip-id': trip.id, 'draft-id': trip.draft?.id }"
        mat-button
        aria-label="Cambia il ruolo di questo invitato"
      >
        <mat-icon aria-hidden="true" fontIcon="edit"></mat-icon>
        Modifica
      </a>
    </div>
    <button
      mat-button
      aria-label="Cambia il ruolo di questo invitato"
      [matMenuTriggerFor]="currentUserAsParticipantStatusMenu"
      class="participant-status {{ currentUserAsParticipant.status }}"
    >
      <mat-icon
        aria-hidden="true"
        [fontIcon]="participantStatusIcon(currentUserAsParticipant.status)"
      ></mat-icon>
      {{
        "trip.participant.status." + currentUserAsParticipant.status | translate
      }}
    </button>
    <section id="header">
      <h2>{{ trip.name }}</h2>
      <div>
        <button type="button" mat-button (click)="openParticipantsDialog()">
          <mat-icon aria-hidden="true" fontIcon="group"></mat-icon>
          {{ trip.participants.length }} invitati
        </button>
      </div>
    </section>

    <section id="overview" class="card">
      <quill-view-html
        *ngIf="trip.description"
        [content]="(trip.description | html)!"
        id="description"
      ></quill-view-html>

      <div class="data">
        <div>
          <h3>
            <mat-icon aria-hidden="true" fontIcon="schedule"></mat-icon>
            Quando
          </h3>
          <p>
            Il
            {{ trip.start_date | date : "dd/MM/yyyy" }}
            dalle
            {{ trip.start_date | date : "HH:mm" }}
            al
            {{ trip.end_date | date : "dd/MM/yyyy" }}
            alle
            {{ trip.end_date | date : "HH:mm" }}
          </p>
        </div>
        <div>
          <h3>
            <mat-icon aria-hidden="true" fontIcon="location_on"></mat-icon>
            Dove
          </h3>
          <ul>
            <li
              *ngFor="
                let location of trip.allLocations | slice : 0 : 4;
                let i = index
              "
            >
              <a [href]="location.link" target="_blank">{{ location.name }}</a>
              <span
                *ngIf="i == 3 && trip.allLocations.length > 4"
                class="badge"
                matTooltip="e altri {{ trip.allLocations.length - 4 }}"
              >
                +{{ trip.allLocations.length - 4 }}
              </span>
            </li>
          </ul>
        </div>
        <div>
          <h3>
            <mat-icon aria-hidden="true" fontIcon="euro"></mat-icon>
            Costo
          </h3>
          <p>
            {{ trip.totalCosts | currency : "€ " }}
          </p>
        </div>
      </div>
    </section>

    <section id="day">
      <app-trip-day-selector
        [days]="trip.days"
        [selectedDay]="selectedDay"
      ></app-trip-day-selector>

      <app-trip-day
        *ngIf="selectedDay"
        [day]="selectedDay"
        [participants]="trip.participants"
      ></app-trip-day>
    </section>
  </div>
</MainLayout>

<mat-menu #currentUserAsParticipantStatusMenu="matMenu">
  <button mat-menu-item (click)="updateParticipantStatus('accepted')">
    <mat-icon
      aria-hidden="true"
      [fontIcon]="participantStatusIcon('accepted')"
    ></mat-icon>
    {{ "trip.participant.status-action.accept" | translate }}
  </button>
  <button mat-menu-item (click)="updateParticipantStatus('maybe')">
    <mat-icon
      aria-hidden="true"
      [fontIcon]="participantStatusIcon('maybe')"
    ></mat-icon>
    {{ "trip.participant.status-action.maybe" | translate }}
  </button>
  <button mat-menu-item (click)="updateParticipantStatus('declined')">
    <mat-icon
      aria-hidden="true"
      [fontIcon]="participantStatusIcon('declined')"
    ></mat-icon>
    {{ "trip.participant.status-action.decline" | translate }}
  </button>
</mat-menu>
