import { Component } from '@angular/core';
import { User } from '@models/User';
import { AuthService } from '@services/auth.service';
import { Trip } from '@models/Trip';
import { map, Observable } from 'rxjs';
import { TripService } from '@services/trip.service';

@Component({
  selector: 'HomePageAuth',
  templateUrl: './home-page-auth.component.html',
  styleUrls: ['./home-page-auth.component.scss']
})
export class HomePageAuthComponent {
  public get user(): User | undefined { return this.authService.user.value; }

  public incomingTrips$: Observable<Trip[]>;
  public completedTrips$: Observable<Trip[]>;

  constructor(
    private readonly authService: AuthService,
    private readonly tripService: TripService,
  ) {
    this.incomingTrips$ = this.tripService.getTripPreviewList('incoming').pipe(map(res => res.data));
    this.completedTrips$ = this.tripService.getTripPreviewList('completed').pipe(map(res => res.data));
  }
}
