import { AuthService } from '@services/auth.service';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.scss']
})
export class LogoutDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<LogoutDialogComponent>,
    private readonly authService: AuthService,
  ) { }

  public logout(): void {
    this.authService.logout();
    this.dialogRef.close();
  }
}
