<CenteredLayout [hasHeader]="false">
  <article>
    <header>
      <img src="/assets/images/logo.webp" alt="" />
      <h1>{{ CONSTANTS.title }}</h1>
    </header>

    <div *ngIf="authService.authenticationInProgress | async; else welcome" class="loading">
      <mat-spinner></mat-spinner>
    </div>

    <ng-template #welcome>
      <h2>Benvenuto</h2>
      <p>Effettua l'accesso per iniziare</p>

      <ul>
        <li *ngFor="let provider of providers">
          <a
            href="{{ environment.backendUrl }}/auth/{{ provider.code }}"
            mat-stroked-button
          >
            <img
              src="/assets/images/providers/{{ provider.code }}.svg"
              alt=""
            />
            {{ provider.text }}
          </a>
        </li>
      </ul>
    </ng-template>
  </article>
</CenteredLayout>
