<ng-template [ngIf]="!loading" [ngIfElse]="isLoadingTemplate">
  <article [class.with-interaction]="!loading">
    <header [title]="trip.name">
      <h3>{{ trip.name }}</h3>
    </header>
    <section>
      <div>
        <mat-icon aria-hidden="true">access_time</mat-icon>
        {{ timeString }}
      </div>
      <div>
        <mat-icon aria-hidden="true">account_circle</mat-icon>
        {{ participantsCount }}
        {{ participantsCount > 1 ? "invitati" : "invitato"
        }}<ng-container *ngIf="participantsAcceptedCount > 0"
          >, {{ participantsAcceptedCount }} accettato</ng-container
        >
      </div>
    </section>
  </article>
</ng-template>
<ng-template #isLoadingTemplate>
  <article>
    <header>
      <h3></h3>
    </header>
    <section>
      <div>
        <mat-icon class="time-icon">access_time</mat-icon>
        <span>
          <span class="loading-skeleton">
            <span>Loading loading</span>
          </span>
        </span>
      </div>
      <div>
        <mat-icon class="person-icon">person</mat-icon>
        <span>
          <span class="loading-skeleton">
            <span>Loading loading loading</span>
          </span>
        </span>
      </div>
    </section>
  </article>
</ng-template>
