import { DOCUMENT } from '@angular/common';
import { CONSTANTS } from './../constants';
import { AfterContentInit, Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterContentInit {

  private isLoadingSystemComponents = {
    i18n: true,
    user: true,
  };

  public isLoading = new BehaviorSubject<boolean>(true);

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly titleService: Title,
    private readonly route: ActivatedRoute,
    private readonly router: Router,

    private readonly translateService: TranslateService,
    private readonly authService: AuthService,
  ) { }

  public ngAfterContentInit(): void {
    this.translateService.setDefaultLang('it');
    this.translateService.use('it');

    this.document.documentElement.setAttribute('lang', this.translateService.currentLang);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.getPageTitle())
    ).subscribe(title => {
      if (title !== null) {
        this.titleService.setTitle(title ? `${title} - ${CONSTANTS.title}` : CONSTANTS.title);
      }
    });

    this.translateService.onLangChange.subscribe(lang => {
      localStorage.setItem('language', lang.lang);
      this.document.documentElement.setAttribute('lang', lang.lang);

      const title = this.getPageTitle();
      if (title !== undefined) {
        this.titleService.setTitle(title ? `${title} - ${CONSTANTS.title}` : CONSTANTS.title);
      }
    });

    this.translateService.get('init').subscribe(() => {
      this.isLoadingSystemComponents.i18n = false;
      this.updateIsLoading();
    });

    this.authService.authenticationInProgress.subscribe(value => {
      this.isLoadingSystemComponents.user = value;
      this.updateIsLoading();
    });
  }

  private getPageTitle(): string | null | undefined {
    let child = this.route.firstChild;

    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.snapshot.data && child.snapshot.data['title'] === '%DEFAULT%') {
        return undefined;
      } else if (child.snapshot.data && child.snapshot.data['title']) {
        return this.translateService.instant('pages.' + child.snapshot.data['title'] + '.title');
      } else {
        return null;
      }
    }

    return null;
  }

  private updateIsLoading(): void {
    this.isLoading.next(Object.values(this.isLoadingSystemComponents).find(l => l) !== undefined);
  }

}
