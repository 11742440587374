<h1 mat-dialog-title>Sei sicuro di voler uscire?</h1>
<div mat-dialog-content>
  <p>
    Uscendo dal tuo profilo, dovrai eseguire nuovamente l'accesso la prossima
    volta che vorrai utilizzare.
  </p>
  <p>
    Desideri effettuare la disconnessione?
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="dialogRef.close()" cdkFocusInitial>No</button>
  <button mat-raised-button color="primary" (click)="logout()">Sì</button>
</div>
