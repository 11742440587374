import { Cost } from '@models/Cost';
import { Component, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Participant } from '@models/Participant';
import { TripUserStatus } from '@enums/TripUserStatus';
import { TripDay } from '@models/Trip';
import { EditorService } from '@services/editor.service';
import { v4 as uuidv4 } from 'uuid';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'trip-day-costs-widget',
  templateUrl: './costs.component.html',
  styleUrls: ['./costs.component.scss']
})
export class CostsWidgetComponent implements OnChanges {

  @Input() public costs!: Cost[];

  @Input() public participants!: Participant[];

  @Input() public day!: TripDay;

  @Input() public mode: 'r' | 'rw' = 'r';

  public form?: FormGroup;

  public totalNotIndividual: number = 0;

  public totalIndividual: number = 0;

  public costForSingleParticipant: number = 0;

  public dialogFormGroup = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    price: new FormControl<number>(0, [Validators.required]),
    is_individual: new FormControl<boolean>(false, [Validators.required]),
  });

  public constructor(
    private readonly editorService: EditorService,
    private readonly dialog: MatDialog,
  ) { }

  public ngOnChanges() {
    const howManyParticipants = this.participants.filter(p => p.status === TripUserStatus.ACCEPTED).length;

    this.form = new FormGroup({});
    this.totalNotIndividual = 0;
    this.totalIndividual = 0;
    this.costForSingleParticipant = 0;

    this.form.valueChanges.subscribe(values => {
      this.totalNotIndividual = this.costs
        .filter(c => !c.is_individual)
        .reduce(
          (currentTotal, cost) => currentTotal + cost.price,
          0
        );

      this.totalIndividual = this.costs
        .filter(c => c.is_individual)
        .reduce(
          (currentTotal, cost) => currentTotal + cost.price,
          0
        );

      this.costForSingleParticipant = (this.totalNotIndividual / values['divideBy']) + this.totalIndividual;
    });

    this.form.addControl('divideBy', new FormControl(howManyParticipants, [Validators.required, Validators.min(1)]));
  }


  public openDialog(dialog: any, edit?: Cost): void {
    if (edit) {
      this.dialogFormGroup.patchValue({
        name: edit.name,
        price: edit.price,
        is_individual: edit.is_individual,
      });
    }

    this.dialog.open(dialog).afterClosed().subscribe((values: any) => {
      if (Object.keys(values).length > 0 && this.dialogFormGroup.valid) {
        const newData: Cost = {
          id: uuidv4(),
          name: values.name,
          price: values.price,
          is_individual: values.is_individual,
        };

        if (edit) {
          this.costs[this.costs.findIndex(l => l.id === edit.id)] = newData;
        } else {
          this.costs.push(newData);
        }

        this.dialogFormGroup.reset();

        this.editorService.setWidgetsData('costs', this.costs);
      }
    });
  }

  public remove({ id }: Cost): void {
    const i = this.costs.findIndex(l => l.id === id);
    this.costs.splice(i, 1);
    this.editorService.setWidgetsData('costs', this.costs);
  }

}
