import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import localeIt from '@angular/common/locales/it';
import { DatePipe, registerLocaleData, TitleCasePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Libraries
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from "@sentry/angular-ivy";
import { QuillModule } from 'ngx-quill';

// Angular Material
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
// import { MatDatepickerModule } from '@angular/material/datepicker';
// import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatSelectModule } from '@angular/material/select';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

// Components
import { HeaderComponent } from '@components/header/header.component';
import { TripDayComponent } from '@components/trip-day/trip-day.component';
import { LocationsWidgetComponent } from '@components/trip-day-widgets/locations/locations.component';
import { CostsWidgetComponent } from '@components/trip-day-widgets/costs/costs.component';
import { ParticipantsWidgetComponent } from '@components/trip-widgets/participants/participants.component';
import { HomePageGuestComponent } from '@pages/home-page/home-page-guest/home-page-guest.component';
import { HomePageAuthComponent } from '@pages/home-page/home-page-auth/home-page-auth.component';
import { TripPreviewComponent } from '@components/trip-preview/trip-preview.component';
import { DaySelectorComponent } from './components/day-selector/day-selector.component';

// Dialogs
import { LogoutDialogComponent } from '@dialogs/logout-dialog/logout-dialog.component';
import { ErrorDialogComponent } from '@dialogs/error-dialog/error-dialog.component';

// Interceptors
import { HeadersInjector } from '@interceptors/HeadersInjector';
import { ErrorHandlingInterceptor } from '@interceptors/ErrorHandlingInterceptor';

// Layouts
import { MainLayoutComponent } from '@layouts/main-layout/main-layout.component';
import { CenteredLayoutComponent } from '@layouts/centered-layout/centered-layout.component';

// Pages
import { HomePageComponent } from '@pages/home-page/home-page.component';
import { LoginPageComponent } from '@pages/login-page/login-page.component';
import { TripDayCostsPageComponent } from '@pages/trips/day/costs-page/costs-page.component';
import { TripDetailsPageComponent } from '@pages/trips/details/trip-details-page.component';
import { TripsPageComponent } from '@pages/trips/trips.component';
import { TripsEditorPageComponent } from '@pages/trips/editor/editor-page.component';

// Pipes
import { HtmlPipe } from '@pipes/Html.pipe';
import { TimePipe } from '@pipes/time.pipe';
import { TranslateRandomPickPipe } from './pipes/translate-random-pick.pipe';

// Directives
import { FormSaverDirective } from '@directives/form-saver.directive';
import { AutoResizeTextAreaDirective } from './directives/auto-resize-text-area.directive';


registerLocaleData(localeIt);

@NgModule({
  declarations: [
    HtmlPipe,
    TimePipe,

    AppComponent,
    HomePageComponent,
    LoginPageComponent,
    MainLayoutComponent,
    HeaderComponent,
    CenteredLayoutComponent,
    LogoutDialogComponent,
    TripDetailsPageComponent,
    TripDayComponent,
    LocationsWidgetComponent,
    CostsWidgetComponent,
    ParticipantsWidgetComponent,
    ErrorDialogComponent,
    TripDayCostsPageComponent,
    HomePageGuestComponent,
    HomePageAuthComponent,
    TripPreviewComponent,
    TripsPageComponent,
    TripsEditorPageComponent,
    TranslateRandomPickPipe,
    FormSaverDirective,
    AutoResizeTextAreaDirective,
    DaySelectorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    ToastrModule.forRoot(),
    LeafletModule,
    QuillModule.forRoot({
      sanitize: true,
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],

          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

          [{ 'color': [] }, { 'background': [] }],
          [{ 'font': [] }],
          [{ 'align': [] }],

          ['clean'],
        ]
      },
      theme: 'bubble',
    }),

    MatMenuModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatInputModule,
    MatSidenavModule,
    MatTableModule,
    MatChipsModule,
    MatStepperModule,
    // MatDatepickerModule,
    // MatLuxonDateModule,
    MatSelectModule,
  ],
  providers: [
    TimePipe,
    DatePipe,
    TitleCasePipe,
    TranslatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInjector,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true,
    },

    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', subscriptSizing: "dynamic" }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
