import { Component, Input } from '@angular/core';

@Component({
  selector: 'CenteredLayout',
  templateUrl: './centered-layout.component.html',
  styleUrls: ['./centered-layout.component.scss']
})
export class CenteredLayoutComponent {

  @Input() public hasHeader = true;

}
