<MainLayout>
  <ng-container *ngIf="trip && selectedDay">
    <a
      [routerLink]="['/', 'trips', trip.id]"
      [queryParams]="{ day: selectedDay.day }"
      mat-button
    >
      <mat-icon aria-hidden="true">arrow_back</mat-icon>
      Torna al viaggio
    </a>
    <header>
      <h2>
        {{ trip.name }}
      </h2>
      <h3>
        <strong>Giorno</strong><br />
        {{ selectedDay.day | date : "dd/MM/yyyy" }}
      </h3>
    </header>

    <form *ngIf="form" [formGroup]="form">
      <table
        aria-label="Rappresenta i costi (sia di gruppo che individuali) della giornata del viaggio con la possibilità di assegnarli o meno a ogni partecipante"
      >
        <thead>
          <tr>
            <td></td>
            <th *ngFor="let cost of selectedDay.costs" scope="col">
              <mat-icon
                [fontIcon]="cost.is_individual ? 'person' : 'group'"
                [attr.aria-label]="
                  cost.is_individual ? 'Individuale' : 'Di gruppo'
                "
                [matTooltip]="cost.is_individual ? 'Individuale' : 'Di gruppo'"
              ></mat-icon>
              <br />
              {{ cost.name }}<br />
              {{ cost.price | currency : "€ " }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="let participant of trip.participants"
            [formGroupName]="participant.user.id!"
          >
            <td>
              {{ participant.user.name }}<br />
              {{
                totalForEachParticipant[participant.user.id!] | currency : "€ "
              }}
            </td>
            <td *ngFor="let cost of selectedDay.costs">
              <mat-checkbox
                title="{{ cost.name }} per {{ participant.user.name }}"
                aria-label="{{ cost.name }} per {{ participant.user.name }}"
                [formControlName]="cost.id"
              ></mat-checkbox>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <strong>
                TOTALE<br />
                {{ totalFromForm | currency : "€ " }}
              </strong>
            </td>
          </tr>
        </tfoot>
      </table>
    </form>
  </ng-container>
</MainLayout>
