import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { CONSTANTS } from './../../../constants';
import { environment } from './../../../environments/environment';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

type Provider = {
  code: string;
  text: string;
};

@Component({
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {

  public readonly environment = environment;
  public readonly CONSTANTS = CONSTANTS;

  public readonly providers: Provider[] = [
    {
      code: 'google',
      text: 'Accedi con Google',
    },
  ];

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public readonly authService: AuthService,
  ) {
    this.authService.user.subscribe(user => {
      if (user) {
        const redirectUrl = sessionStorage.getItem('redirect-url');

        if (redirectUrl) {
          this.router.navigateByUrl(redirectUrl);
          sessionStorage.removeItem('redirect-url');
        } else {
          this.router.navigate(['/']);
        }
      }
    });

    const token = this.route.snapshot.queryParamMap.get('token');

    if (token) {
      this.authService.loginUserWithToken(token);
    }
  }

  public ngOnInit(): void {
    this.document.body.classList.add('login');
  }

  public ngOnDestroy(): void {
    this.document.body.classList.remove('login');
  }

}
