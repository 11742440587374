<h2 mat-dialog-title>
  <mat-icon aria-hidden="true" fontIcon="group"></mat-icon>
  Invitati
</h2>

<mat-dialog-content>
  <div id="participants">
    <form
      *ngIf="
        tripUserService.hasAnyOfRoles(currentUserAsParticipant.role, [
          TripUserRole.ORGANIZER,
          TripUserRole.ASSISTANT
        ])
      "
      [formGroup]="newParticipantsForm"
      (submit)="invite()"
    >
      <mat-form-field
        hintLabel="Scrivi uno o più indirizzi e-mail seguiti da degli spazi"
      >
        <mat-label>Invita altre persone</mat-label>
        <mat-chip-grid #chipGrid aria-label="Invita altre persone">
          <mat-chip-row
            *ngFor="let participant of newParticipants"
            (removed)="chipRemove(participant)"
            [editable]="true"
            (edited)="chipEdit(participant, $event)"
            [aria-description]="'Premi INVIO per modificare ' + participant"
          >
            {{ participant }}
            <button matChipRemove [attr.aria-label]="'Rimuovi ' + participant">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
          <input
            placeholder="Indirizzi e-mail"
            [matChipInputFor]="chipGrid"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="chipAdd($event)"
          />
        </mat-chip-grid>

        <button
          *ngIf="newParticipantsForm.valid"
          mat-raised-button
          matSuffix
          color="primary"
          type="submit"
        >
          <mat-icon aria-hidden="true" fontIcon="person_add"></mat-icon>
          Invita
        </button>
      </mat-form-field>
    </form>

    <table
      aria-label="Elenca i partecipanti invitati e il loro stato di risposta all'invito"
    >
      <thead>
        <tr>
          <td></td>
          <td></td>
          <th scope="col">Stato</th>
          <td
            *ngIf="
              tripUserService.hasAnyOfRoles(currentUserAsParticipant.role, [
                TripUserRole.ORGANIZER,
                TripUserRole.ASSISTANT
              ])
            "
          ></td>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let participant of participants">
          <td class="role">
            <button
              mat-icon-button
              aria-label="Cambia il ruolo di questo invitato"
              [matMenuTriggerFor]="roleMenu"
              [matTooltip]="
                'trip.participant.role.' + participant.role | translate
              "
              matTooltipPosition="above"
              (click)="clickedParticipant = participant"
              [disabled]="
                !tripUserService.hasAnyOfRoles(currentUserAsParticipant.role, [
                  TripUserRole.ORGANIZER
                ])
              "
            >
              <mat-icon
                aria-hidden="false"
                [attr.aria-label]="
                  'trip.participant.role.' + participant.role | translate
                "
                [fontIcon]="participantRoleIcon(participant.role)"
              ></mat-icon>
            </button>
          </td>
          <th scope="row">
            {{ participant.user.name }}
          </th>
          <td class="participant-status {{ participant.status }}">
            {{ "trip.participant.status." + participant.status | translate }}
          </td>
          <td
            *ngIf="
              tripUserService.hasAnyOfRoles(currentUserAsParticipant.role, [
                TripUserRole.ORGANIZER,
                TripUserRole.ASSISTANT
              ])
            "
          >
            <button
              mat-button
              matTooltip="Rimuovi partecipante"
              matTooltipPosition="above"
              class="remove"
              (click)="remove(participant.user.id!)"
            >
              <mat-icon aria-hidden="true" fontIcon="close"></mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Chiudi</button>
</mat-dialog-actions>

<mat-menu #roleMenu="matMenu">
  <button mat-menu-item (click)="updateRole(clickedParticipant!, 'organizer')">
    <mat-icon
      aria-hidden="true"
      [fontIcon]="participantRoleIcon('organizer')"
    ></mat-icon>
    {{ "trip.participant.role.organizer" | translate }}
  </button>

  <button mat-menu-item (click)="updateRole(clickedParticipant!, 'assistant')">
    <mat-icon
      aria-hidden="true"
      [fontIcon]="participantRoleIcon('assistant')"
    ></mat-icon>
    {{ "trip.participant.role.assistant" | translate }}
  </button>

  <button mat-menu-item (click)="updateRole(clickedParticipant!, 'guest')">
    <mat-icon
      aria-hidden="true"
      [fontIcon]="participantRoleIcon('guest')"
    ></mat-icon>
    {{ "trip.participant.role.guest" | translate }}
  </button>
</mat-menu>
