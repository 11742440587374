import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TripDay } from '@models/Trip';

@Component({
  selector: 'app-trip-day-selector',
  templateUrl: './day-selector.component.html',
  styleUrls: ['./day-selector.component.scss']
})
export class DaySelectorComponent implements OnChanges {

  @Input() public days!: TripDay[];

  @Input() public selectedDay?: TripDay;

  @Input() public mode: 'r' | 'rw' = 'r';

  @Input() public addDayBoundaries?: string[];

  @Output() public onDayAdded = new EventEmitter<string>();

  public constructor(
    private readonly dialog: MatDialog,
  ) { }

  public ngOnChanges(): void {
    if (this.addDayBoundaries) {
      this.addDayBoundaries[0] = this.addDayBoundaries[0].split('T')[0];
      this.addDayBoundaries[1] = this.addDayBoundaries[1].split('T')[0];
    }
  }

  public openDialog(dialog: any): void {
    this.dialog.open(dialog).afterClosed().subscribe((addedDay: string) => {
      if (addedDay.length > 0) {
        this.onDayAdded.emit(addedDay);
      }
    });
  }

}
