import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseResponse } from '@models/BaseResponse';
import { Participant } from '@models/Participant';
import { TripDay } from '@models/Trip';
import { TripDraft } from '@models/TripDraft';
import { EditorService } from '@services/editor.service';
import { ToastService } from '@services/toast.service';
import { TripDraftsService } from '@services/trip-drafts.service';
import { TripService } from '@services/trip.service';
import { BehaviorSubject, Observable, distinctUntilChanged, map, of, shareReplay, take } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
  templateUrl: './editor-page.component.html',
  styleUrls: ['./editor-page.component.scss']
})
export class TripsEditorPageComponent implements OnInit {

  public mode?: 'create' | 'edit' = 'create';

  public draftsList?: Observable<BaseResponse<TripDraft[]>>;

  public days = new BehaviorSubject<TripDay[]>([]);

  public participants = new BehaviorSubject<Participant[]>([]);

  public formGroups = {
    general: new FormGroup({
      name: new FormControl('', [Validators.required]),
    }),
    details: new FormGroup({
      description: new FormControl(''),
      start_date: new FormControl('', [Validators.required]),
      end_date: new FormControl('', [Validators.required]),
      hasAtLeastOneDay: new FormControl(false, [Validators.requiredTrue]),
    }),
  };

  public get tripName(): string | null | undefined {
    return this.formGroups.general.value.name;
  }

  public constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly toastService: ToastService,
    private readonly tripDraftsService: TripDraftsService,
    private readonly tripService: TripService,
    public readonly editorService: EditorService,
  ) { }

  public ngOnInit(): void {
    this.route.queryParamMap.pipe(
      map(params => params.get('trip-id')),
      distinctUntilChanged(),
      map(() => this.route.snapshot.queryParamMap)
    ).subscribe(params => {
      const tripID = params.get('trip-id');

      if (tripID && !params.has('draft-id')) {
        this.loadTrip(tripID);
      }
    });

    this.route.queryParamMap.pipe(
      map(params => params.get('draft-id')),
      distinctUntilChanged(),
    ).subscribe(draftID => {
      if (draftID) {
        this.loadDraft(draftID);
      } else {
        this.draftsList = this.tripDraftsService.list().pipe(shareReplay());
      }
    });

    this.route.queryParamMap.subscribe(params => {
      const selectedDay = params.get('day');
      this.editorService.selectedDay = selectedDay ? this.days.value.find(d => d.day === selectedDay) : undefined;
    });

    this.days.subscribe(days => {
      this.formGroups.details.patchValue({
        hasAtLeastOneDay: days.length > 0,
      });

      const dayFromQueryParam = this.route.snapshot.queryParamMap.get('day');
      if (dayFromQueryParam) {
        this.editorService.selectedDay = days.find(d => d.day === dayFromQueryParam);
      }
    });
  }

  public isStepValid(group: FormGroup): boolean {
    return group.valid;
  }

  public onDayAdded(day: string, additionalParams?: any): void {
    if (this.days.value.find(d => d.day === day)) {
      this.toastService.warning('C\'è già questa giornata');
    } else {
      this.days.next([
        ...this.days.value,
        {
          day,
          id: uuidv4(),
          data: {},
          created_at: '',
          updated_at: '',
          locations: [],
          costs: [],
          ...additionalParams,
        } satisfies TripDay
      ].sort((
        a: TripDay, b: TripDay) =>
        a.day < b.day ?
          -1 :
          a.day > b.day ?
            1 :
            0
      ));
    }
  }

  private buildDraftObject(): TripDraft {
    const daysData: { [day: string]: object } = {};

    for (const day of this.days.value) {
      daysData[day.day] = this.editorService.widgetsData[day.id];
    }

    return {
      id: this.route.snapshot.queryParamMap.get('draft-id') ?? '',
      name: this.formGroups.general.value.name!,
      forms: {
        general: this.formGroups.general.value,
        details: this.formGroups.details.value,
      },
      days_data: daysData,
      trip_id: this.route.snapshot.queryParamMap.get('trip-id') ?? undefined,
    } satisfies TripDraft;
  }

  public saveDraft(): void {
    this.tripDraftsService.createOrUpdate(this.buildDraftObject()).subscribe({
      next: response => {
        this.router.navigate([], {
          queryParams: {
            'draft-id': response.data.id,
          },
          queryParamsHandling: 'merge',
        });
      },
    });
  }

  public openLoadDraftsDialog(dialog: any): void {
    this.dialog.open(dialog).afterClosed().subscribe((tripDraft: Partial<TripDraft> | null) => {
      if (tripDraft) {
        this.router.navigate([], {
          queryParams: {
            'draft-id': tripDraft.id,
          },
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  private loadDraft(id: string): void {
    this.tripDraftsService.load(id).subscribe({
      next: response => {
        const draft = response.data;

        if (draft.trip_id) {
          this.router.navigate([], {
            queryParams: {
              'trip-id': draft.trip_id,
            },
            queryParamsHandling: 'merge',
            replaceUrl: true,
          });
        }

        if (draft) {
          if (draft.forms.general) {
            this.formGroups.general.patchValue(draft.forms.general);
          }

          if (draft.forms.details) {
            this.formGroups.details.patchValue(draft.forms.details);
          }

          for (const [day, widgets] of Object.entries(draft.days_data)) {
            this.onDayAdded(day, {
              locations: widgets.locations ?? [],
              costs: widgets.costs ?? []
            });

            this.editorService.selectedDay = this.days.value.find(d => d.day === day);
            for (const [widgetID, data] of Object.entries(widgets)) {
              this.editorService.setWidgetsData(widgetID, data);
            }
          }
        }
      }
    });
  }

  public deleteDraft({ id }: TripDraft): void {
    this.draftsList!.pipe(
      take(1),
      map(val => {
        this.tripDraftsService.delete(id!).subscribe({
          next: () => {
            const i = val.data.findIndex(l => l.id === id);
            val.data.splice(i, 1);
            this.draftsList = of(val);
          }
        });

        return val;
      })
    ).subscribe();
  }

  public createOrUpdateTrip(): void {
    const draft = this.buildDraftObject();
    draft.draftID = draft.id;
    draft.id = this.route.snapshot.queryParamMap.get('trip-id') ?? undefined;

    this.tripService.createOrUpdate(draft).subscribe({
      next: response => {
        this.router.navigate(['/', 'trips', response.data.id]);
      },
    });
  }

  private loadTrip(id: string): void {
    if (this.mode === 'create') {
      this.mode = 'edit';

      this.tripService.getById(id).pipe(shareReplay()).subscribe({
        next: response => {
          const trip = response.data;

          this.formGroups.general.patchValue({
            name: trip.name,
          });

          this.formGroups.details.patchValue({
            description: trip.description,
            start_date: trip.start_date,
            end_date: trip.end_date,
          });

          for (const day of Object.values(trip.days)) {
            this.onDayAdded(day.day, {
              locations: day.locations ?? [],
              costs: day.costs ?? []
            });

            this.editorService.selectedDay = this.days.value.find(d => d.day === day.day);
            this.editorService.setWidgetsData('locations', day.locations);
            this.editorService.setWidgetsData('costs', day.costs);
          }
        }
      });
    }
  }

}
