import { Participant } from '@models/Participant';
import { TripDay } from '@models/Trip';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-trip-day',
  templateUrl: './trip-day.component.html',
  styleUrls: ['./trip-day.component.scss']
})
export class TripDayComponent {

  @Input() public day!: TripDay;

  @Input() public participants!: Participant[];

  @Input() public mode: 'r' | 'rw' = 'r';

}
