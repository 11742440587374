import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(value: string): unknown {
    const splitted = value.split(':');
    return `${splitted[0]}:${splitted[1]}`;
  }

}
