<article class="col-span-2" [ngClass]="{ 'no-padding': mode === 'r' }">
  <trip-day-locations-widget
    [mode]="mode"
    [locations]="day.locations"
  ></trip-day-locations-widget>
</article>

<article>
  <trip-day-costs-widget
    [mode]="mode"
    [costs]="day.costs"
    [participants]="participants"
    [day]="day"
  ></trip-day-costs-widget>
</article>
