import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {

  public constructor(
    private readonly toastr: ToastrService,
    private readonly translateService: TranslateService,
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get("skip-error-handling") === "true")
      return next.handle(req);

    return next.handle(req)
      .pipe(
        tap({
          error: (error: any) => {
            // Has a message by backend
            if (
              error.error.error || // Backend error
              error.error.error?.code // Backend custom error code
            ) {
              this.toastr.error(this.translateService.instant(`backend-errors.${error.error.error.code ?? error.error.error}`));
              return of(error);
            }

            this.toastr.error(this.translateService.instant(`backend-errors.generic`));

            return throwError(() => "An unexpected problem has occurred");
          }
        }),
      );
  }
}
