import { Component } from '@angular/core';
import {AuthService} from "../../services/auth.service";

@Component({
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {
  public get isAuthenticated(): boolean {
    return this.authService.isAuthenticated;
  }

  constructor(
    private readonly authService: AuthService
  ) {
  }
}
