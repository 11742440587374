import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService,
  ) { }

  public show<ConfigPayload = any>(message: string, title?: string, override?: Partial<IndividualConfig<ConfigPayload>>, type?: 'success' | 'warning' | 'error' | 'info') {
    return this.toastrService.show(this.translateService.instant(message), title ? this.translateService.instant(title) : undefined, override, `toast-${type}`);
  }

  public success<ConfigPayload = any>(message: string, title?: string, override?: Partial<IndividualConfig<ConfigPayload>>) {
    return this.toastrService.success(this.translateService.instant(message), title ? this.translateService.instant(title) : undefined, override);
  }

  public error<ConfigPayload = any>(message: string, title?: string, override?: Partial<IndividualConfig<ConfigPayload>>) {
    return this.toastrService.error(this.translateService.instant(message), title ? this.translateService.instant(title) : undefined, override);
  }

  public info<ConfigPayload = any>(message: string, title?: string, override?: Partial<IndividualConfig<ConfigPayload>>) {
    return this.toastrService.info(this.translateService.instant(message), title ? this.translateService.instant(title) : undefined, override);
  }

  public warning<ConfigPayload = any>(message: string, title?: string, override?: Partial<IndividualConfig<ConfigPayload>>) {
    return this.toastrService.warning(this.translateService.instant(message), title ? this.translateService.instant(title) : undefined, override);
  }
}
