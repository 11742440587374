import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateRandomPick'
})
export class TranslateRandomPickPipe implements PipeTransform {

  public constructor(
    private readonly translate: TranslateService,
  ) { }

  transform(value: string, ...args: unknown[]): string {
    const values = this.translate.instant(value);

    return values[Math.floor(Math.random() * values.length)];
  }

}
