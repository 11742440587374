import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { Observable, catchError, of, map } from 'rxjs';

export namespace AuthenticatedGuard {

  const takeToLogin = (state: RouterStateSnapshot, router: Router) => {
    sessionStorage.setItem('redirect-url', state.url.toString());
    router.navigate(['/', 'login']);
  };

  export const canActivate = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean => {
    const authService = inject(AuthService);
    const router = inject(Router);

    if (authService.isAuthenticated) {
      return true;
    }

    return authService.authenticationInProgress.pipe(
      map(u => {
        if (u) {
          return true;
        } else {
          takeToLogin(state, router);
          return false;
        }
      }),
      catchError(() => {
        takeToLogin(state, router);
        return of(false);
      })
    );
  }

  export const canActivateChild = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) => canActivate(route, state);
}
