import { Pipe, PipeTransform, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: 'html' })
export class HtmlPipe implements PipeTransform {
  public constructor(
    private readonly sanitized: DomSanitizer
  ) { }

  public transform(value: string) {
    return this.sanitized.sanitize(SecurityContext.HTML, value);
  }
}
