<header>
  <div>
    <div>
      <a routerLink="/" class="logo">
        <img src="/assets/images/logo.webp" alt="" height="60" />
        <h1>{{ CONSTANTS.title }}</h1>
      </a>
    </div>

    <nav>
      <ul>
        <ng-container *ngIf="authService.user | async; else notLoggedIn">
          <li>
            <a [routerLink]="['/', 'trips']" mat-button> Viaggi </a>
          </li>
          <li>
            <button mat-button [matMenuTriggerFor]="menu">
              <img
                *ngIf="(authService.user | async)?.avatar"
                [src]="(authService.user | async)?.avatar"
                alt=""
              />
              {{ (authService.user | async)?.name }}
            </button>
          </li>
        </ng-container>
        <ng-template #notLoggedIn>
          <li>
            <a [routerLink]="['/', 'login']" mat-button> Accedi </a>
          </li>
          <li>
            <a [routerLink]="['/', 'login']" mat-button> Registrati </a>
          </li>
        </ng-template>
      </ul>
    </nav>
  </div>
</header>

<mat-menu #menu="matMenu">
  <button mat-menu-item>Profilo</button>
  <button mat-menu-item (click)="logout()">Esci</button>
</mat-menu>
