<button
  *ngIf="mode === 'rw'"
  mat-raised-button
  (click)="openDialog(dialog)"
  style="margin-bottom: 1rem"
>
  Aggiungi un costo
</button>

<form *ngIf="costs.length > 0 && form" [formGroup]="form">
  <table
    aria-label="Rappresenta i costi (sia di gruppo che individuali) della giornata del viaggio"
  >
    <thead>
      <tr>
        <td></td>
        <th scope="col">Costo</th>
        <th
          *ngIf="mode === 'rw'"
          scope="col"
          class="actions"
          aria-label="Azioni"
        ></th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let cost of costs">
        <th scope="row">
          <mat-icon
            [fontIcon]="cost.is_individual ? 'person' : 'group'"
            [attr.aria-label]="cost.is_individual ? 'Individuale' : 'Di gruppo'"
            [matTooltip]="cost.is_individual ? 'Individuale' : 'Di gruppo'"
          ></mat-icon>
          {{ cost.name }}
        </th>
        <td>
          {{ cost.price | currency : "€ " }}
        </td>
        <td *ngIf="mode === 'rw'">
          <button
            mat-icon-button
            aria-label="Modifica"
            (click)="openDialog(dialog, cost)"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            color="warn"
            aria-label="Rimuovi"
            (click)="remove(cost)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>

    <tfoot class="total">
      <tr>
        <td>
          <strong>Totale</strong>
        </td>
        <td>
          {{ totalNotIndividual + totalIndividual | currency : "€ " }}
        </td>
      </tr>
    </tfoot>
  </table>

  <ng-container *ngIf="mode === 'r'">
    <footer>
      <div>
        <mat-form-field>
          <mat-label>Dividi il totale per</mat-label>
          <input
            matInput
            type="number"
            min="1"
            placeholder="Es. 4"
            formControlName="divideBy"
          />
        </mat-form-field>
      </div>

      <p *ngIf="form.value.divideBy > 0" style="margin-top: 1rem">
        <strong>Quota per partecipante</strong>
        <span class="cost">{{
          costForSingleParticipant | currency : "€ "
        }}</span>
      </p>
    </footer>

    <p class="more">Ci sono dei costi di competenza di singoli partecipanti?</p>
    <a [routerLink]="[day.day, 'costs']" mat-raised-button
      >Dividi singolarmente i costi</a
    >
  </ng-container>
</form>

<p *ngIf="costs.length === 0" role="alert" class="empty">
  Nessun costo previsto per questo giorno
</p>

<ng-template #dialog>
  <form [formGroup]="dialogFormGroup" mat-dialog-content class="costs-dialog">
    <mat-form-field>
      <mat-label>Nome</mat-label>
      <input
        matInput
        type="text"
        placeholder="Ricarica auto elettrica"
        formControlName="name"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Costo</mat-label>
      <input matInput type="number" placeholder="40" formControlName="price" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Individuale o di gruppo</mat-label>
      <mat-select formControlName="is_individual">
        <mat-option [value]="true"> Individuale </mat-option>
        <mat-option [value]="false"> Gruppo </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Annulla</button>
    <button
      mat-button
      type="submit"
      color="primary"
      [mat-dialog-close]="dialogFormGroup.value"
      [disabled]="!dialogFormGroup.valid"
    >
      Salva
    </button>
  </div>
</ng-template>
