<ng-container *ngIf="user">
  <h2>Bentornato {{ user.name }}</h2>

  <div class="sections">
    <section>
      <h3>I tuoi prossimi viaggi</h3>
      <div>
        <ng-container *ngIf="incomingTrips$ | async as trips; else loading">
          <ng-template [ngIf]="trips.length > 0" [ngIfElse]="noTrips">
            <trip-preview
              *ngFor="let trip of trips"
              [trip]="trip"
              routerLink="/trips/{{ trip.id }}"
            ></trip-preview>
          </ng-template>
          <ng-template #noTrips>
            <div class="empty-preview-message" role="alert">
              <span>Sembra che tu non abbia viaggi in programma</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #loading>
          <trip-preview
            *ngFor="let _ of [0, 0]"
            [loading]="true"
          ></trip-preview>
        </ng-template>
      </div>
    </section>

    <section>
      <h3>I tuoi viaggi passati</h3>
      <div>
        <ng-container *ngIf="completedTrips$ | async as trips; else loading">
          <ng-template [ngIf]="trips.length > 0" [ngIfElse]="noTrips">
            <trip-preview
              *ngFor="let trip of trips"
              [trip]="trip"
              routerLink="/trips/{{ trip.id }}"
            ></trip-preview>
          </ng-template>
          <ng-template #noTrips>
            <div class="empty-preview-message" role="alert">
              <span>Non hai ancora fatto viaggi</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #loading>
          <trip-preview
            *ngFor="let _ of [0, 0]"
            [loading]="true"
          ></trip-preview>
        </ng-template>
      </div>
    </section>
  </div>
</ng-container>
