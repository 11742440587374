import { TripUserRole } from '@enums/TripUserRole';
import { CONSTANTS } from '../../../../constants';
import { Title } from '@angular/platform-browser';
import { Cost } from '@models/Cost';
import { Location } from '@models/Location';
import { TripEnhanced, TripDay } from '@models/Trip';
import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ParticipantsWidgetComponent } from '@components/trip-widgets/participants/participants.component';
import { TripService } from '@services/trip.service';
import { TripUserStatus } from '@enums/TripUserStatus';
import { ToastService } from '@services/toast.service';
import { Participant } from '@models/Participant';
import { AuthService } from '@services/auth.service';
import { TripUserService } from '@services/trip-user.service';

@Component({
  templateUrl: './trip-details-page.component.html',
  styleUrls: ['./trip-details-page.component.scss']
})
export class TripDetailsPageComponent {

  public readonly TripUserRole = TripUserRole;

  private id?: string;

  public loading: boolean = true;

  public trip?: TripEnhanced;

  public selectedDay?: TripDay;

  public currentUserAsParticipant?: Participant;

  public constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,

    private readonly titleService: Title,
    private readonly tripService: TripService,
    private readonly toastService: ToastService,
    private readonly authService: AuthService,

    public readonly tripUserService: TripUserService,
  ) {
    this.route.queryParamMap.subscribe(() => {
      if (this.trip) {
        this.afterTripLoaded();
      }
    });

    this.route.params.subscribe(params => {
      this.id = params['id'];

      this.load();
    });
  }

  private load(): void {
    this.loading = true;

    this.tripService.getById(this.id!).subscribe({
      next: resp => {
        const allLocations: { [id: string]: Location } = {};
        const allCosts: Cost[] = [];

        for (const day of resp.data.days) {
          day.locations.forEach(l => allLocations[l.id] = l);
          day.costs.forEach(c => allCosts.push(c));
        }

        this.trip = {
          ...resp.data,
          allLocations: Object.values(allLocations),
          allCosts,
          totalCosts: allCosts.reduce(
            (sum, c) => sum + c.price,
            0
          )
        };

        this.titleService.setTitle(`${this.trip.name} - ${CONSTANTS.title}`);

        const preselectedDay = this.route.snapshot.queryParams['day'];

        // If a day is not selected, autoselect the first day (if it is available)
        if (this.trip.days[0] && !preselectedDay) {
          this.router.navigate([], {
            queryParams: {
              day: this.trip.days[0].day
            },
            replaceUrl: true,
            queryParamsHandling: 'merge',
          });
        } else {
          this.afterTripLoaded();
        }
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  public openParticipantsDialog(): void {
    this.dialog.open(ParticipantsWidgetComponent, {
      width: '100%',
      maxWidth: '500px',
      data: {
        trip: this.trip!,
        participants: this.trip!.participants,
        currentUserAsParticipant: this.currentUserAsParticipant,
      },
    });
  }

  public updateParticipantStatus(status: string): void {
    const s = status as TripUserStatus;
    this.tripService.updateParticipantStatus(this.trip!.id, s).subscribe({
      next: () => {
        this.toastService.show(
          `trip.participant.status-toast.${status}`,
          undefined,
          undefined,
          s === TripUserStatus.ACCEPTED ? 'success' : 'info'
        );

        this.currentUserAsParticipant!.status = s;

        this.router.navigate(
          [],
          {
            queryParams: {
              confirmation: undefined,
            },
            queryParamsHandling: 'merge',
            replaceUrl: true,
          }
        );

        if (!this.selectedDay) {
          this.changeSelectedDay(this.route.snapshot.queryParams['day']);
        }
      },
    });
  }

  public participantStatusIcon(status: string) {
    switch (status) {
      case TripUserStatus.PENDING:
        return 'update';
      case TripUserStatus.ACCEPTED:
        return 'check';
      case TripUserStatus.MAYBE:
        return 'question_mark';
      case TripUserStatus.DECLINED:
        return 'close';
      default:
        return '';
    }
  }

  private afterTripLoaded(): void {
    const queryParams = this.route.snapshot.queryParams;

    this.changeSelectedDay(queryParams['day']);

    this.currentUserAsParticipant = this.trip!.participants.find(p => p.user.id === this.authService.user.value!.id)!;

    if (queryParams['confirmation']) {
      this.updateParticipantStatus(queryParams['confirmation']);
    }
  }

  private changeSelectedDay(day: string): void {
    this.selectedDay = this.trip!.days.find(d => d.day === day);
  }

}
