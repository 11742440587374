import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Cost } from '@models/Cost';
import { Trip, TripDay } from '@models/Trip';
import { TripService } from '@services/trip.service';
import { CONSTANTS } from 'src/constants';

type CostForm = {
  [participantID: string]: {
    [costID: string]: boolean;
  };
};

@Component({
  templateUrl: './costs-page.component.html',
  styleUrls: ['./costs-page.component.scss']
})
export class TripDayCostsPageComponent {

  public trip?: Trip;

  public selectedDay?: TripDay & {
    costs: Cost[],
  };

  public form?: FormGroup;

  /** The participant id is the key and the value is the total amount that that participant has to pay */
  public totalForEachParticipant: { [participantID: string]: number } = {};

  public get totalFromForm() {
    return Object.values(this.totalForEachParticipant).reduce((currentTotal, price) => currentTotal + price, 0);
  }

  public constructor(
    private readonly route: ActivatedRoute,

    private readonly titleService: Title,
    private readonly tripService: TripService,
  ) {
    this.route.params.subscribe(params => {
      this.load(params['id'], params['day']);
    });
  }

  private load(id: string, day: string): void {
    this.trip = undefined;

    this.tripService.getById(id).subscribe({
      next: resp => {
        this.trip = resp.data;

        this.selectedDay = this.trip.days?.find(d => d.day === day);

        this.titleService.setTitle(`${this.trip.name} - ${CONSTANTS.title}`);

        this.buildForm();
      },
    });
  }

  private buildForm(): void {
    this.form = new FormGroup({});

    for (const participant of this.trip!.participants) {
      const group = new FormGroup({});
      for (const cost of this.selectedDay!.costs) {
        group.addControl(cost.id, new FormControl(true));
      }
      this.form.addControl(participant.user.id!, group);
    }

    this.calculate(this.form.value);

    this.form.valueChanges.subscribe((values: CostForm) => {
      this.calculate(values);
    });
  }

  private calculate(values: CostForm): void {
    /** An object where the key is the costID and the value is the array of participants IDs that must pay for that cost */
    const costsWithParticipants: { [costID: string]: string[] } = {};

    /** An object where the key is the costID and the value is the amount which everybody that has to pay that cost must pay */
    const costForEach: { [costID: string]: number } = {};

    this.totalForEachParticipant = {};

    for (const cost of this.selectedDay!.costs) {
      costsWithParticipants[cost.id] = [];

      for (const [participantID, costs] of Object.entries(values)) {
        /** The current participant should pay for the current cost? */
        const shoudPayFor = Object.entries(costs).find(([id, isChecked]) => id === cost.id && isChecked) !== undefined;
        if (shoudPayFor) {
          costsWithParticipants[cost.id].push(participantID);
        }
      }

      // Gets the current cost total, divides it by the participants that must pay for that cost and saves it
      costForEach[cost.id] = cost.price / costsWithParticipants[cost.id].length;
    }

    for (const participant of this.trip!.participants) {
      // Calculates total for each participant only for group costs
      this.totalForEachParticipant[participant.user.id!] = Object.entries(costsWithParticipants)
        .filter(([cid, participants]) => {
          const { is_individual } = this.selectedDay!.costs.find(c => c.id === cid)!;

          return participants.includes(participant.user.id!) && !is_individual;
        }) // Gets costs IDs that the current participant must pay
        .map(([cid, _]) => costForEach[cid]) // Gets the portion of the cost
        .reduce((currentTotal, price) => currentTotal + price, 0); // and sums them up

      // Calculates total for each participant but assign the whole cost for individual costs and sum the result to the previous total
      this.totalForEachParticipant[participant.user.id!] += Object.entries(costsWithParticipants)
        .map(([cid, participants]) => {
          const { price, is_individual } = this.selectedDay!.costs.find(c => c.id === cid)!;

          return participants.includes(participant.user.id!) && is_individual ? price : 0;
        }) // Gets costs prices that the current participant must pay
        .reduce((currentTotal, price) => currentTotal + price, 0);
    }
  }

}
