import { EventEmitter, Injectable } from '@angular/core';
import { TripDay } from '@models/Trip';

type WidgetData = { [dayID: string]: { [widgetID: string]: object } };

@Injectable({
  providedIn: 'root'
})
export class EditorService {

  private _widgetsData: WidgetData = {};

  public readonly onChanges = new EventEmitter<WidgetData>();

  public selectedDay?: TripDay;

  public get widgetsData() {
    return this._widgetsData;
  }

  public setWidgetsData(widgetID: string, data: any) {
    if (!this._widgetsData[this.selectedDay!.id]) {
      this._widgetsData[this.selectedDay!.id] = {};
    }

    this._widgetsData[this.selectedDay!.id][widgetID] = data;
    this.onChanges.emit(this._widgetsData);
  }


}
