import { CONSTANTS } from './../../../../constants';
import { Component } from '@angular/core';

@Component({
  selector: 'HomePageGuest',
  templateUrl: './home-page-guest.component.html',
  styleUrls: ['./home-page-guest.component.scss']
})
export class HomePageGuestComponent {

  public readonly CONSTANTS = CONSTANTS;

}
