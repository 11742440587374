<ng-container *ngIf="mode === 'rw'; else read">
  <button mat-raised-button (click)="openDialog(dialog)">
    Aggiungi una destinazione
  </button>

  <table
    *ngIf="locations.length > 0"
    aria-label="Elenca le destinazioni previste per questa giornata, con orario, coordinate, link e azioni di modifica o cancellazione"
  >
    <thead>
      <tr>
        <th scope="col">Orario e luogo</th>
        <th scope="col">Coordinate GPS</th>
        <th scope="col">Link</th>
        <th scope="col" class="actions" aria-label="Azioni"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let location of locations">
        <th scope="row">
          {{ location.pivot.time }}
          &bull;
          {{ location.name }}
        </th>
        <td>
          {{ location.latitude }},
          {{ location.longitude }}
        </td>
        <td class="link">
          {{ location.link }}
        </td>
        <td>
          <button
            mat-icon-button
            aria-label="Modifica"
            (click)="openDialog(dialog, location)"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            color="warn"
            aria-label="Rimuovi"
            (click)="remove(location)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <ng-template #dialog>
    <form [formGroup]="formGroup" mat-dialog-content>
      <mat-form-field>
        <mat-label>Nome</mat-label>
        <input
          matInput
          type="text"
          placeholder="Musei civici di Milano"
          formControlName="name"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Orario</mat-label>
        <input
          matInput
          type="time"
          placeholder="11:00"
          formControlName="time"
        />
      </mat-form-field>
      <div class="lat-lng">
        <mat-form-field>
          <mat-label>Latitudine</mat-label>
          <input
            matInput
            type="text"
            placeholder="45.4345"
            formControlName="latitude"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Longitudine</mat-label>
          <input
            matInput
            type="text"
            placeholder="15.3455"
            formControlName="longitude"
          />
        </mat-form-field>
      </div>
      <mat-form-field>
        <mat-label>Link</mat-label>
        <input
          matInput
          type="text"
          placeholder="https://musei.it/milano"
          formControlName="link"
        />
      </mat-form-field>
    </form>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>Annulla</button>
      <button
        mat-button
        type="submit"
        color="primary"
        [mat-dialog-close]="formGroup.value"
        [disabled]="!formGroup.valid"
      >
        Salva
      </button>
    </div>
  </ng-template>
</ng-container>

<ng-template #read>
  <div
    *ngIf="locations && locations.length > 0 && options && fitBounds"
    class="map"
    leaflet
    [leafletOptions]="options"
    [leafletLayers]="layers"
    [leafletFitBounds]="fitBounds"
  ></div>

  <button
    *ngIf="locations && locations.length > 0"
    type="button"
    id="toggle-list"
    (click)="showList = !showList"
    [ngClass]="{ close: showList }"
    [matTooltip]="showList ? 'Chiudi' : 'Destinazioni'"
  >
    <mat-icon
      aria-hidden="false"
      aria-label="Mostra o nascondi la lista delle destinazioni"
      [fontIcon]="showList ? 'close' : 'list'"
    ></mat-icon>
  </button>

  <aside *ngIf="showList">
    <h4>Destinazioni</h4>
    <ul>
      <li *ngFor="let location of locations">
        <span class="time">{{ location.pivot.time | time }}</span
        >&nbsp;<a
          *ngIf="location.link; else noLink"
          [href]="location.link"
          target="_blank"
          >{{ location.name }}</a
        >
        <ng-template #noLink>
          {{ location.name }}
        </ng-template>
      </li>
    </ul>
  </aside>
</ng-template>

<p *ngIf="locations.length === 0" role="alert" class="empty">
  Nessuna destinazione prevista per questo giorno
</p>
