<MainLayout *ngIf="mode">
  <h2 *ngIf="formGroups">
    {{ tripName || "pages.trips." + mode + ".title" | translate }}
  </h2>

  <mat-stepper [linear]="true" #stepper labelPosition="bottom">
    <mat-step [stepControl]="formGroups.general">
      <ng-template matStepLabel>
        <mat-icon aria-hidden="true" fontIcon="rocket_launch"></mat-icon>
        {{ "trip.editor.general.label" | translate }}
      </ng-template>

      <form [formGroup]="formGroups.general" class="general">
        <mat-form-field>
          <mat-label translate>trip.editor.general.name.label</mat-label>
          <input
            matInput
            [placeholder]="
              'trip.editor.general.name.placeholders' | translateRandomPick
            "
            formControlName="name"
            required
          />
          <button
            type="submit"
            mat-icon-button
            matStepperNext
            matSuffix
            color="primary"
            [disabled]="!isStepValid(formGroups.general)"
            [title]="'next' | translate"
          >
            <mat-icon aria-hidden="true" fontIcon="arrow_forward"></mat-icon>
          </button>
        </mat-form-field>

        <ng-container *ngIf="(draftsList | async)?.data?.length">
          <div class="drafts">
            <p>Vuoi riprendere un viaggio lasciato a metà?</p>
            <button
              mat-raised-button
              (click)="openLoadDraftsDialog(loadDraftsDialog)"
            >
              Riprendiamolo!
            </button>
          </div>
        </ng-container>
      </form>
    </mat-step>

    <mat-step [stepControl]="formGroups.details">
      <ng-template matStepLabel>
        <mat-icon aria-hidden="true" fontIcon="architecture"></mat-icon>
        {{ "trip.editor.details.label" | translate }}
      </ng-template>

      <ng-template matStepContent>
        <form [formGroup]="formGroups.details" class="details" #editorContainer>
          <quill-editor
            placeholder="Questo è il posto migliore per descrivere il viaggio. Dove si va? Che attrezzatura bisogna portare? Pranzo al sacco o ristorante?"
            formControlName="description"
            [bounds]="editorContainer"
          ></quill-editor>
          <div class="dates">
            <mat-form-field>
              <mat-label>Data di inizio</mat-label>
              <input
                matInput
                type="datetime-local"
                placeholder="Data di inizio"
                formControlName="start_date"
                #startDate
              />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Data di fine</mat-label>
              <input
                matInput
                type="datetime-local"
                placeholder="Data di fine"
                formControlName="end_date"
                #endDate
              />
            </mat-form-field>
          </div>

          <ng-container
            *ngIf="
              (days | async)!.length > 0 ||
              (startDate.value.length > 0 && endDate.value.length > 0)
            "
          >
            <app-trip-day-selector
              mode="rw"
              [days]="(days | async)!"
              [selectedDay]="editorService.selectedDay"
              [addDayBoundaries]="[startDate.value, endDate.value]"
              (onDayAdded)="onDayAdded($event)"
            ></app-trip-day-selector>

            <app-trip-day
              mode="rw"
              *ngIf="editorService.selectedDay"
              [day]="editorService.selectedDay"
              [participants]="(participants | async)!"
            ></app-trip-day>
          </ng-container>

          <footer>
            <button mat-button color="primary" (click)="saveDraft()">
              <mat-icon>save</mat-icon>
              Salva bozza
            </button>
            <button mat-button matStepperPrevious>
              {{ "back" | translate }}
            </button>
            <button
              type="submit"
              mat-raised-button
              matStepperNext
              color="primary"
              [disabled]="!isStepValid(formGroups.details)"
            >
              {{ "next" | translate }}
            </button>
          </footer>
        </form>
      </ng-template>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>
        <mat-icon aria-hidden="true" fontIcon="check_small"></mat-icon>
        {{ "trip.editor.end.label" | translate }}
      </ng-template>
      <ng-template matStepContent>
        <button mat-button matStepperPrevious>
          {{ "back" | translate }}
        </button>

        <div class="end">
          <h2>È tutto pronto</h2>
          <p>
            Potrai invitare a partecipare altre persone dopo che avrai concluso
            la creazione di questo viaggio.
          </p>

          <button
            mat-raised-button
            color="primary"
            (click)="createOrUpdateTrip()"
          >
            Andiamo!
          </button>
        </div>
      </ng-template>
    </mat-step>
  </mat-stepper>
</MainLayout>

<ng-template #loadDraftsDialog>
  <h1 mat-dialog-title>Scegli una bozza</h1>

  <div mat-dialog-content class="drafts-dialog">
    <ul>
      <li *ngFor="let draft of (draftsList | async)?.data">
        <button mat-button color="primary" [mat-dialog-close]="draft">
          {{ draft.name }}
        </button>

        <button
          mat-icon-button
          color="warn"
          aria-label="Rimuovi"
          (click)="deleteDraft(draft)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </li>
    </ul>
  </div>

  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Annulla</button>
  </div>
</ng-template>
