<section class="welcome">
  <h2>Benvenuto su {{ CONSTANTS.title }}</h2>
  <h3>Progetta al meglio i tuoi viaggi</h3>
</section>

<div>
  <section>
    <h2>Facile</h2>
    <p>
      L'interfaccia semplice abbinata a potenti strumenti di organizzazione e
      gestione, renderanno progettare il tuo viaggio un piacere.
    </p>
  </section>

  <section>
    <h2>Preciso</h2>
    <p>
      Definisci il tuo viaggio nei minimi particolari, puoi scegliere gli orari,
      le destinazioni in un'ordine preciso e visualizzarle su una mappa o un
      elenco, dividere i costi facilmente fra i partecipanti e molto altro
      ancora.
    </p>
  </section>

  <section>
    <h2>Gratuito e riservato</h2>
    <p>
      L'utilizzo dell'intera piattaforma è completamente gratuito e non sono
      presenti pubblicità di alcun genere.
    </p>
    <p>
      Non elaboriamo i tuoi dati a fini statistici e non li vendiamo a soggetti
      terzi. I tuoi dati sono privati e visibili solamente ai gestori della
      piattaforma per eventuali necessità di manutenzione o assistenza.
    </p>
  </section>

  <section>
    <h2>Inizia ora</h2>
    <p>
      Ci piacerebbe aiutarti ad organizzare il tuo prossimo viaggio, che dici di
      darci una possibilità?
    </p>
    <a [routerLink]="['/', 'login']" mat-raised-button> Accedi </a>
    <a [routerLink]="['/', 'login']" mat-raised-button> Registrati </a>
  </section>
</div>
