import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponse } from '@models/BaseResponse';
import { TripDraft } from '@models/TripDraft';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TripDraftsService {

  public constructor(
    private readonly http: HttpClient,
  ) { }

  public list() {
    return this.http.get<BaseResponse<TripDraft[]>>(`${environment.backendUrl}/api/trip-drafts`);
  }

  public createOrUpdate(draft: TripDraft) {
    return this.http.post<BaseResponse<{ id: string }>>(`${environment.backendUrl}/api/trip-drafts`, draft);
  }

  public load(id: string) {
    return this.http.get<BaseResponse<TripDraft>>(`${environment.backendUrl}/api/trip-drafts/${id}`);
  }

  public delete(id: string) {
    return this.http.delete<BaseResponse<true>>(`${environment.backendUrl}/api/trip-drafts/${id}`);
  }

}
