import { CookieService } from 'ngx-cookie-service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HeadersInjector implements HttpInterceptor {

  public constructor(
    private readonly cookieService: CookieService,
    private readonly translateService: TranslateService,
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string | null = this.cookieService.get('token');
    const setHeaders: { [key: string]: string } = {
      'Accept-Language': this.translateService.currentLang ?? localStorage.getItem('language') ?? 'en',
    };

    if (token) {
      setHeaders['Authorization'] = `Bearer ${token}`;
    }

    req = req.clone({
      setHeaders
    });

    return next.handle(req);
  }
}
